<template>
  <div class="checkout-confirm-page">
    <v-container ref="scroll-container" overflow-y-auto class="px-4 pt-3 pt-sm-6 pb-0">
      <div class="r---card r---card-mobile mb-0 mb-sm-7 mt-0 mt-sm-0">
        <div data-cy-supplier class="pt-5 pb-5 r---order-section px-0 px-sm-6">
          <div class="r---order-heading">
            {{ $t("checkout_confirmation_header2") }}
          </div>
          <v-alert
            cy-checkout-validation-message
            ref="error-alert"
            v-if="errors.length > 0"
            type="error"
            text
            class="r---alert mb-0 mt-3"
          >
            <div v-for="error_text in errors" :key="error_text">
              {{ error_text }}
            </div>
          </v-alert>
        </div>

        <v-card data-cy-checkout-items>
          <div
            cy-checkout-item
            v-for="item in items"
            :key="item.offer.id"
            :class="item_class(item)"
            class="r---productList-item px-0 px-sm-6"
          >
            <div class="r---productList-item-inner">
              <v-list-item-avatar tile class="r---productList-imageWrapper ma-0">
                <v-img :src="item.offer.sellable.image_url" class="r---productList-image"></v-img>
              </v-list-item-avatar>

              <v-list-item-content class="r---productList-metaWrapper py-0">
                <v-list-item-title class="r---productList-meta1 mb-1">
                  {{ item.offer.sellable.name }}
                  <span data-cy-item-out-of-stock v-if="item.offer.out_of_stock_at" class="r---pill r---pill--red">
                    {{ $t("checkout_confirmation_no_stock") }}
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="r---productList-meta2 mb-1">
                  {{ $t("checkout_confirmation_item_unit") }}:
                  {{ item.offer.price }} &times; {{ formatted_qty(item) }}
                  <span data-cy-item-updated v-if="item.updated" class="r---pill r---pill--yellow">
                    {{ $t("checkout_confirmation_price_changed_tag") }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-title class="r---productList-meta1 mt-auto">
                  <span class="r---productList-meta1-text">
                    {{ $t("checkout_confirmation_item_total") }}:
                    {{ number_to_currency(item.qty * item.offer.price_in_cents) }}
                  </span>
                </v-list-item-title>
                <v-list-item-title v-if="item.offer.stokvel" class="r---productList-meta1 mt-auto" style="color: var(--v-promotion-base)">
                  Stokvel&nbsp;
                  <span v-if='item_savings_in_cents(item)' class="r---productList-meta1-text">
                    Savings:
                    {{ formatted_item_savings(item) }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </div>

            <ComboItems
              v-if="
                item.offer.sellable.combo_items &&
                item.offer.sellable.combo_items.length > 0
              "
              :items="item.offer.sellable.combo_items"
            />
          </div>
        </v-card>

        <div>
          <v-card
            data-cy-checkout-totals
            v-if="credit_in_cents || is_delivery_order || discount_in_cents"
            class="r---detailsList mx-0 mx-sm-6"
          >
            <div class="r---detailsList-item">
              {{ $t("checkout_confirm_subtotal") }}:
              <span class="r---detailsList-meta2">{{
                number_to_currency(subtotal)
              }}</span>
            </div>
            <div v-if="credit_in_cents" class="r---detailsList-item">
              {{ $t("checkout_confirm_credit") }}:
              <span class="r---detailsList-meta2">
                &minus; {{ number_to_currency(credit_in_cents) }}
              </span>
            </div>
            <div v-if="discount_in_cents" class="r---detailsList-item">
              <span>
                {{ $t("checkout_confirm_discount") }}
                <strong>{{ discount_code }}</strong>
              </span>
              <span class="r---detailsList-meta2">
                &minus; {{ number_to_currency(discount_in_cents) }}
              </span>
            </div>
            <div
              v-if="is_delivery_order"
              class="r---detailsList-item border border-green pa-2"
            >
              {{ $t("checkout_confirm_delivery") }}:
              <span
                v-if="delivery_cost_in_cents > 0"
                class="r---detailsList-meta2 green--text"
              >
                &plus; {{ number_to_currency(delivery_cost_in_cents) }}
              </span>
              <span v-else class="r---detailsList-meta2 green--text">
                {{ $t("checkout_confirm_delivery_free") }}
              </span>
            </div>
          </v-card>
          <div
            class="r---total-wrapper mx-0 mx-sm-6"
            :class="total_savings_in_cents > 0 ? 'pb-2' : 'pb-6'"
          >
            {{ $t("checkout_confirm_total") }}:
            <span class="ml-auto r---total-value">{{ formatted_total }}</span>
          </div>
          <div
            v-if="total_savings_in_cents > 0"
            class="r---text-size-1 d-flex pb-6 mx-0 mx-sm-6"
            style="color: var(--v-promotion-base)"
          >
            {{ $t("add_to_cart_stokvel_savings") }}:
            <span class="ml-auto">
              {{ number_to_currency(total_savings_in_cents) }}
            </span>
          </div>
        </div>

        <div ref="controls" class="r---card-footer mx-n4 mx-sm-0 px-4 px-sm-6 pt-sm-8 pb-7">
          <div class="r---input-group mb-7">
            <label class="r---input-label mb-2">
              {{ $t(`checkout_confirm_${dispatch_method}_address`) }}:
            </label>
            <div v-if="!requires_shipping" class="r---supplier-name">
              {{ this.$store.state.checkout.supplier.name }}
            </div>
            <div data-cy-dispatch-address class="r---text-size-1 r---text-color-1">
              {{ shipping_address }}
            </div>
          </div>

          <div v-if="discount_codes_enabled" class="r---input-group mb-8">
            <label class="r---input-label">{{
              $t("checkout_confirm_discount_code_label")
            }}</label>
            <div class="d-flex">
              <v-text-field
                v-model="discount_code_input"
                hide-details="auto"
                solo
                class="r---text-field"
                :placeholder="$t('checkout_confirm_discount_code_placeholder')"
                :error-messages="discount_code_error"
                v-on:keydown.enter="validate_discount_code"
              >
              </v-text-field>
              <v-btn
                style="margin-left: 4px"
                @click="validate_discount_code"
                class="r---button r---button-primary"
                >Apply</v-btn
              >
            </div>
          </div>

          <div data-cy-payment-method class="r---input-group mb-8">
            <label class="r---input-label"> {{$t("checkout_confirm_payment_method")}} </label>
            <div data-cy-payment-select class="pa-0">
              <v-select
                v-model="payment_provider"
                :items="payment_options"
                hide-details="auto"
                solo
                class="r---text-field"
              ></v-select>
            </div>
          </div>

          <div data-cy-dispatch-method class="r---input-group">
            <label class="r---input-label">{{
              $t(`checkout_confirm_${dispatch_method}_date`)
            }}</label>
            <div data-cy-dispatch-timeslots class="pa-0">
              <v-select
                v-model="collection_date"
                :items="timeslot_options"
                :label="$t('checkout_confirm_dispatch_slot_placeholder')"
                hide-details="auto"
                :error="!!dispatch_error"
                :error-messages="dispatch_error"
                :disabled="timeslots_loading"
                solo
                class="r---text-field"
              >
              </v-select>
            </div>
          </div>

          <div cy-requires-bags v-if="bags_feature_enabled && !requires_shipping" class="mb-8 r---text-size-1">
            <p class="mb-0">
              {{ $t("checkout_confirm_label_bags", { price: number_to_currency(bag_price_in_cents) }) }}
            </p>
            <div class="d-flex justify-space-around my-2">
              <i class="r---icon-bag flex-shrink-0 align-self-center" height="24" width="24"/>
              <v-radio-group
                v-model="requires_bags"
                row
                hide-details
                class="mt-0"
              >
                <v-radio
                  v-for="value in [true, false]"
                  :key="`requires_bags_${value}`"
                  :label="value == true ? 'Yes' : 'No'"
                  :value="value"
                ></v-radio>
              </v-radio-group>
            </div>
          </div>

          <v-btn
            data-cy-confirm-order
            @click="confirm_order"
            :loading="loading"
            block
            depressed
            class="r---button r---button-primary r---btn-primary-action"
          >
            {{ $t("checkout_confirm_button_confirm") }}
          </v-btn>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="validation_modal" width="500" persistent>
      <div cy-checkout-validation-modal class="r---modal">
        <div class="r---circle r---circle-red mx-auto mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-exclamation-lg"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
            />
          </svg>
        </div>

        <p class="r---text-size-1">
          {{ $t("checkout_validation_stock_short_line_1") }}
        </p>
        <p class="r---text-size-1">
          {{ $t("checkout_validation_stock_short_line_2") }}
        </p>

        <v-btn to="/basket" @click.passive="validation_modal = false" class="r---button r---button-primary">
          OK
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { number_to_currency, as_json } from "@/lib/helpers";
import { DateTime } from "luxon";
import ComboItems from "@/components/combo_items";
import { nextTick } from "vue";

export default {
  components: { ComboItems },

  mounted() {
    this.$store.dispatch("checkout/validate")
      .then((validation) => {
        if (validation == undefined) return;
        this.handle_validation(validation);
        this.stokvel = validation.stokvel;
      })
      .finally(() => {
        this.fetch_timeslot_options();
      })

    this.$store.commit("layout/page_nav", {
      title: this.$t("checkout_confirmation_header"),
      back_target: this.navigate_back_target,
    });
  },

  beforeDestroy() {
    clearTimeout(this.scroll_to_bottom_timeout);
    clearTimeout(this.scroll_timeout);
  },

  data() {
    return {
      checkout_outro: false,
      errors: [],
      scroll_to_bottom_timeout: null,
      scroll_timeout: null,
      timeslot_options: [],
      timeslots_loading: false,
      validation_modal: false,
      discount_codes_enabled: false,
      discount_code_error: null,
      discount_in_cents: 0,
      discount_code: "",
      dispatch_error: null,
      stokvel: false,
      stokvel_errors: []
    };
  },

  watch: {
    payment_provider() {
      this.fetch_timeslot_options();
    },
  },

  computed: {
    navigate_back_target() {
      if (this.$store.getters["checkout/shipping_options"].length > 1) {
        return (
          "/checkout/" +
          (this.requires_shipping ? "shipping_address" : "dispatch")
        );
      }
      return "/basket";
    },

    client_config() {
      return this.$store.state.checkout.client_config;
    },

    payment_provider: {
      get() {
        return this.$store.state.checkout.payment_provider;
      },
      set(provider) {
        this.$store.commit("checkout/set_payment_provider", provider);
      },
    },

    requires_bags: {
      get() {
        return this.$store.state.checkout.requires_bags || false;
      },
      set(value) {
        this.$store.commit("checkout/set_requires_bags", value);
      },
    },

    collection_date: {
      get() {
        return this.$store.state.checkout.collection_date;
      },
      set(value) {
        this.dispatch_error = null;
        this.$store.commit("checkout/set_collection_date", value);
      },
    },

    discount_code_input: {
      get() {
        return this.$store.state.checkout.discount_code_input;
      },
      set(value) {
        this.$store.commit("checkout/set_discount_code_input", value);
      },
    },

    dispatch_method() {
      return this.requires_shipping ? "delivery" : "collection";
    },

    formatted_dispatch_method() {
      return (
        this.dispatch_method[0].toUpperCase() + this.dispatch_method.slice(1)
      );
    },

    requires_shipping() {
      return this.$store.state.checkout.requires_shipping;
    },

    subtotal() {
      return this.$store.getters["checkout/subtotal_in_cents"];
    },

    credit_in_cents() {
      return this.$store.state.checkout.credit_in_cents;
    },

    bag_price_in_cents() {
      if (this.$store.getters["application/is_agency"]) {
        return this.$store.state.application.features.bag_price_in_cents;
      }
      return this.$store.state.checkout.client_config.bag_price_in_cents;
    },

    bags_feature_enabled() {
      if (this.$store.getters["application/is_agency"]) {
        return this.$store.state.application.features.bags;
      }
      return this.$store.state.checkout.client_config.bags ? true : false;
    },

    total_savings_in_cents() {
      let savings_in_cents = 0;
      for (const item of this.items.filter(
        (item) => item.offer.base_price_in_cents,
      )) {
        savings_in_cents += this.item_savings_in_cents(item) * item.qty;
      }
      return savings_in_cents;
    },

    is_delivery_order() {
      return this.dispatch_method.toUpperCase() === "DELIVERY";
    },

    delivery_cost_in_cents() {
      return this.$store.state.checkout.shipping_cost_in_cents;
    },

    formatted_total() {
      return this.number_to_currency(
        this.$store.getters["checkout/total_in_cents"],
      );
    },

    payment_options() {
      let options = this.$store.getters["checkout/payment_options"];
      options = options.filter(
        (option) =>
          option !== "MOYA" ||
          JSON.parse(window.localStorage.getItem("moya-discover")),
      );
      this.payment_provider = options.includes(this.payment_provider)
        ? this.payment_provider
        : options[0];
      return options.map((option) => ({
        value: option,
        text: this.payment_options_text(option),
      }));
    },

    items() {
      return this.$store.state.checkout.items;
    },

    loading() {
      return this.$store.state.checkout.loading;
    },

    order_id() {
      return this.$store.state.orders.current?.id;
    },

    shipping_address() {
      if (this.$store.state.checkout.requires_shipping) {
        return this.$store.state.checkout.shipping_address.formatted;
      }
      return this.$store.state.checkout.supplier.formatted_address;
    },

    remote_validation_failed() {
      return (
        !this.$store.state.checkout.validated_at ||
        this.$store.state.checkout.updated ||
        this.$store.state.checkout.out_of_stock ||
        this.$store.state.checkout.total_error ||
        this.$store.state.checkout.discount_code_error ||
        this.dispatch_error ||
        this.stokvel_errors.length
      );
    },
  },

  methods: {
    formatted_item_savings(item) {
      const savings_in_cents = this.item_savings_in_cents(item);
      if (savings_in_cents > 0) {
        return this.number_to_currency(savings_in_cents * item.qty);
      }
      return null;
    },

    item_savings_in_cents(item) {
      if (!item.offer) return null;
      const pack_size = Number(item.offer.sellable.pack);
      const base_price = Number(item.offer.base_price_in_cents);
      const price = Number(item.offer.price_in_cents);
      if (pack_size <= 1) return null;
      if (base_price <= 0) return null;
      const cost_at_base_price = base_price * pack_size;
      return cost_at_base_price - price;
    },

    formatted_unit_price(offer) {
      const text = `${this.$store.getters["application/currency"]} ${offer.price}`;
      return offer.per_kg ? `${text} /${offer.per_kg_symbol}` : text;
    },

    formatted_qty(item) {
      if (item.offer.per_kg) {
        let qty = Number(item.qty) / Number(item.offer.per_kg_conversion_rate);
        qty = ["kg", "l"].includes(item.offer.per_kg_symbol.toLowerCase())
          ? qty.toFixed(3)
          : qty.toFixed(0);
        return `${qty} ${item.offer.per_kg_symbol}`;
      }
      return item.qty;
    },

    payment_options_text(provider) {
      const map = {
        CASH: this.requires_shipping
          ? this.$t("checkout_confirm_payment_option_cash_deliver")
          : this.$t("checkout_confirm_payment_option_cash_collect"),
        PAYGATE: this.$t("checkout_confirm_payment_option_paygate"),
        DEBTORCARD: this.$t("checkout_confirm_payment_option_debtorcard"),
        SHOP2SHOP: "Shop2Shop",
        MOYA: "MoyaPay",
        YOCO: "Pay Online (Card or EFT)",
      };
      return map[provider];
    },

    number_to_currency(cents) {
      return number_to_currency({
        symbol: this.$store.getters["application/currency"],
        cents: cents,
      });
    },

    item_class(item) {
      return item.updated || item.offer.out_of_stock_at ? "" : "";
    },

    confirm_order() {
      if (!this.valid_selections()) return;

      this.$store.dispatch("checkout/validate")
        .then((validation) => {
          this.dispatch_error = validation.dispatch_error;
          this.stokvel_errors = validation.stokvel_errors;
          if (this.remote_validation_failed) {
            this.handle_validation(validation);
            return;
          }
          this.$store.dispatch("checkout/register_order", {stokvel: this.stokvel})
            .then((order) => {
              if (!this.$store.state.orders.current) return;

              if (this.$store.getters["checkout/requires_online_payment"]) {
                this.$router.push(`/checkout/payment?order_id=${order.id}`);
              } else {
                // complete order
                this.$store.commit("checkout/show_outro", true);
                this.$store.dispatch("analytics/purchase");
                this.$router.push(`/orders/${order.id}`).then(() => {
                  this.$store.dispatch("checkout/reset");
                });
              }
            })
            .catch((error) =>
              console.error(
                "confirmation.confirm_order.register_order.caught:",
                error,
              ),
            );
        })
        .catch((error) =>
          console.error("confirmation.confirm_order.validate.caught:", error),
        );
    },

    valid_selections() {
      this.payment_error = null;
      if (!this.collection_date) {
        this.dispatch_error = this.$t(
          "checkout_confirm_dispatch_slot_required",
          { dispatch: this.formatted_dispatch_method },
        );
        return false;
      }
      if (!this.payment_provider) {
        this.payment_error = this.$t(
          "checkout_confirm_payment_method_required",
        );
        return false;
      }
      return true;
    },

    fetch_timeslot_options() {
      this.timeslots_loading = true;
      this.timeslot_options = [];
      if (typeof this.collection_date === "string") {
        this.previous_collection_date = this.collection_date.slice();
        this.collection_date = null;
      }
      this.$store.dispatch("checkout/fetch_timeslot_options", {stokvel: this.stokvel})
        .then((timeslots) => {
          this.timeslot_options = timeslots;
          if (this.timeslot_options.length === 1) {
            this.collection_date = this.timeslot_options[0].value;
          }
          if (timeslots.map((option) => option.value).includes(this.previous_collection_date)) {
            this.collection_date = this.previous_collection_date.slice();
          }
        })
        .catch((error) => {
          this.dispatch_error = "Problem fetching timeslots. Please try again";
          console.error("fetch supplier timeslots error:", error);
        })
        .finally(() => {
          this.timeslots_loading = false;
        });
    },

    validate_discount_code() {
      this.$store
        .dispatch("checkout/validate")
        .then((validation) => this.handle_validation(validation));
    },

    handle_validation(validation) {
      console.log('handle_validation', validation)
      if (validation.out_of_stock) this.validation_modal = true;
      this.discount_codes_enabled = validation.discount_codes_enabled;
      this.$store.dispatch("cart/reset", validation.items);
      if (validation.updated) {
        this.errors.push(this.$t("checkout_validation_prices_updated_line_1"));
        this.errors.push(this.$t("checkout_validation_prices_updated_line_2"));
      }
      if (validation.total_error) {
        this.errors.push(
          this.$t("checkout_validation_order_minimum_total_line_1"),
        );
      }
      if (validation.discount_code_error === "invalid") {
        this.discount_code_error = this.$t(
          "checkout_validation_discount_code_invalid",
        );
        this.discount_in_cents = 0;
      } else {
        this.discount_code_error = null;
        this.discount_in_cents = validation.discount_in_cents;
        this.discount_code = validation.discount_code;
      }
      this.stokvel_errors.splice(0)
      if (validation.stokvel_errors) { this.errors.push(...validation.stokvel_errors) }
      this.$nextTick(() => {
        this.errors.length > 0 ? this.scroll_to(this.$refs["error-alert"]) : this.scroll_to(this.$refs["controls"], 1000)
      });
    },

    scroll_to(element) {
      console.log("scroll_to", element)
      if (this.scroll_to_bottom_timeout)
        clearTimeout(this.scroll_to_bottom_timeout);
      this.scroll_timeout = setTimeout(() => {
        this.$vuetify.goTo(element, {
          offset: 8,
          container: document.documentElement,
        });
      }, 200);
    },
  },
};
</script>

<style></style>
